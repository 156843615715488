/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from 'react-router-dom'
import CircleIcon from '@mui/icons-material/Circle'
import { Container, Grid, Card, CardActionArea} from '@mui/material'
import './App.css'
import listaFormato from './helpers/lista_formatos'

export function Home() {

  const navigate = useNavigate()

  const cardClick = (formato: string) => {    
    let urlFormat = ''
    switch (formato) {
    case 'UNIMARC':
      urlFormat = 'u'
      break  
    case 'SUPER10':
      urlFormat = 's'
      break 
    case 'MAYORISTA10':
      urlFormat = 'm'
      break 
    case 'ALVI':
      urlFormat = 'a'
      break 
    default:
      break
    }
    navigate(`/${'search/'+urlFormat}`)
  }

  return (
    <>
      <Container maxWidth="sm" className='container'>
        <div className='inside-container'
        >
          <Grid container  spacing={3} justifyContent="center" alignItems="center" >
            <Grid item xs={12} justifyContent="start" alignItems="start">
              <p>
                <CircleIcon style={{fontSize: '10px', marginRight: '2%'}}/>
                Selecciona una cadena para continuar.
              </p>
            </Grid>
  
            {listaFormato.map((item, index) => (
              <Grid key={index}  item xs={12} sm={6} md={6} 
                sx={{ display: 'flex', justifyContent: 'center' }}>
                <Card 
                  sx={{ width: '100%', borderRadius: '15px', boxShadow: `0px 2px 2px ${item.color}`}} 
                  variant='outlined' 
                  onClick={() => cardClick(item.formato)}>
                  <CardActionArea>
                    <img src={item.imageSrc}  
                      className={item.formato !== 'ALVI' ? 'home-logo' : 'home-logo-alvi'} alt="logo" />
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
            
          </Grid>
        </div>
      </Container>
    </>
  )
}