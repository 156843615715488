import array_locales from '../data/localesActivos.json'

export interface Local {
  code:string,
  number:number,
  name:string
}

export function checkLocalIsActive(code:string):boolean {
  const result=array_locales.findIndex((item)=>(item.code===code))>=0
  return result
}

export function findLocal(code:string):Local|undefined {
  const local = array_locales.find((item)=>(item.code===code))
  return local
}

