import { useNavigate } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { Button, Grid, Box, TextField, Container, Alert,Collapse } from '@mui/material'
import CasoContext from './store/CasoContext'
import { object, string } from 'zod'
import './App.css'
import {saveCaso,SaveCasoResult} from './api/caso'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import SendIcon from '@mui/icons-material/Send'

const registerSchemaA = object({
  detalle: string().min(1, 'debes ingresar nombre'),
})

const registerSchemaB = object({
  pNombre: string().min(1, 'debes ingresar nombre'),
/*   pMarca: string().min(1, 'debes ingresar nombre'),
  pEnvase: string().min(1, 'debes ingresar nombre'),
  detalle: string().min(1, 'debes ingresar nombre'), */
})

const registerSchemaC = object({
  fMotivo: string().min(1, 'debes ingresar nombre'),
  fQuien: string().min(1, 'debes ingresar nombre'),
})

export function Detalle() {
  const context = useContext(CasoContext)
  const [isSending, setIsSending]=useState(false)
  const [open, setOpen] = useState(false)

  //funcion para guardar un caso, usa saveCaso
  const handleSave = () => {
    if(context?.caso.local !== ''){
      setIsSending(true)
      saveCaso(context?.caso).then((res:SaveCasoResult)=>{
        if (res.result){
          context?.updateField('id_ticket',res.id_ticket)
          navigate('/send')
        }else{
          navigate('/error')
        }
      }).catch((err)=>{
        navigate('/error')
      }).finally(()=>{
        setIsSending(false)
      })
    } else {
      setOpen(true)
    }
  }

  const backHome = () => {
    setOpen(false)
    navigate('/')
  }

  const navigate = useNavigate()  

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const validaCasoA = (caso:any) => {
    const resul = registerSchemaA.safeParse(caso)
    return resul.success
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const validaCasoB = (caso:any) => {
    const resul = registerSchemaB.safeParse(caso)
    return resul.success
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const validaCasoC = (caso:any) => {
    const resul = registerSchemaC.safeParse(caso)
    return resul.success
  }

  const validaCaso = (tipo_caso:string, caso:any) => {
    switch(tipo_caso) {
    case 'Reclamo':
      return validaCasoA(caso)
    case 'Consulta':
      return validaCasoA(caso)
    case 'Producto no Encontrado':
      return validaCasoB(caso)
    case 'Felicitaciones':
      return validaCasoC(caso)
    default:
      return false
    }

  }

  useEffect(() => {
    /// console.log('Det:',context?.caso)
  }, [context?.caso])

  return (
    <>
      <Container maxWidth="sm" className='container sec3'>
        <Box component="form" noValidate   sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '2%' }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <img 
                style={{
                  width: context?.caso.appFormato === 'ALVI' ? '30%': ''
                }}
                src={context?.caso.appLogo} className="App-logo" alt="logo" />
            </Grid>
            <Grid item xs={12}>
              <p style={{ width: '100%', textAlign: 'center' }}>
                <strong style={{ fontSize: '1.2rem' }}>Ingreso {context?.caso.tipo_caso}</strong>
              </p>
            </Grid>



            { (context?.caso.tipo_caso === 'Reclamo' || context?.caso.tipo_caso === 'Consulta') &&
              <>
                <Grid item xs={12}>
                  <TextField
                    name="detalle"
                    required
                    fullWidth
                    size="small"
                    id="detalle"
                    multiline
                    rows={4}                
                    label="Ingrese con el mayor detalle posible su inquietud"
                    sx={{ bgcolor: '#f5f5f5' }}
                    onChange={context?.handleInput}
                    value={context?.caso.detalle}
                  />
                </Grid>
              </>
            }


            { context?.caso.tipo_caso === 'Producto no Encontrado' &&
              <>
                <Grid item xs={12}>
                  <TextField
                    name="pNombre"
                    required
                    fullWidth
                    size="small"
                    id="pNombre"
                    label="Nombre de producto"
                    sx={{ bgcolor: '#f5f5f5' }}
                    onChange={context?.handleInput}
                    value={context?.caso.pNombre}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="pMarca"
                    fullWidth
                    size="small"
                    id="pMarca"
                    label="Marca de producto"
                    sx={{ bgcolor: '#f5f5f5' }}
                    onChange={context?.handleInput}
                    value={context?.caso.pMarca}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="pEnvase"
                    fullWidth
                    size="small"
                    id="pEnvase"
                    label="Peso o Gramaje de producto"
                    sx={{ bgcolor: '#f5f5f5' }}
                    onChange={context?.handleInput}
                    value={context?.caso.pEnvase}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="detalle"
                    fullWidth
                    size="small"
                    id="detalle"
                    multiline
                    rows={4}                
                    label="Especifique con el mayor detalle posible el producto no encontrado"
                    sx={{ bgcolor: '#f5f5f5' }}
                    onChange={context?.handleInput}
                    value={context?.caso.detalle}
                  />
                </Grid>
              </>
            }

            { context?.caso.tipo_caso === 'Felicitaciones' &&
              <>
                <Grid item xs={12}>
                  <TextField
                    name="fMotivo"
                    required
                    fullWidth
                    multiline
                    rows={4} 
                    size="small"
                    id="fMotivo"
                    label="Especifique el motivo por el cual quiere felicitar"
                    sx={{ bgcolor: '#f5f5f5' }}
                    onChange={context?.handleInput}
                    value={context?.caso.fMotivo}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="fQuien"
                    required
                    fullWidth
                    size="small"
                    id="fQuien"
                    label="A quien se felicita"
                    sx={{ bgcolor: '#f5f5f5' }}
                    onChange={context?.handleInput}
                    value={context?.caso.fQuien}
                  />
                </Grid>
              </>
            }

            { !open  &&
              <>
                <Grid container spacing={2} sx={{mt: 4}}>
              
                  <Grid item xs={6}>
                    <Button variant="text"   size='large'
                      style={{borderRadius: '10px',color: context?.caso.appColor}}
                      startIcon={<KeyboardArrowLeftIcon />}
                      onClick={() => {context?.updateField('reset',''); navigate('/dat')}}>Volver</Button>
                  </Grid>
                  <Grid item xs={6}>

                    <Button variant="contained" color="success" size='large'
                      endIcon={<SendIcon />}
                      style={{borderRadius: '10px'}}
                      disabled={isSending || !validaCaso(context?.caso.tipo_caso,context?.caso)} 
                      onClick={() => {handleSave()}}>Enviar</Button>
                  </Grid>
                </Grid>
              </>
            }
            <Collapse in={open}>
              <Grid container sx={{mt: 4}}>
                <Grid item >
               
                  <Alert
                    variant="filled"
                    severity="error"
                  >
                    <p style={{fontSize: '15px'}}>
                    Lo sentimos, se perdieron algunos datos requeridos en el proceso, por favor vuelva a completarlos.
                    </p>
              

                    <Button color="inherit" size="small" sx={{mt: 0}}
                      onClick={() => {backHome()}}>
                      Ir a Inicio
                    </Button>
                  </Alert>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>

        </Box>
      </Container>
    </>)
}