/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from 'react-router-dom'
import { useEffect, useState, useContext } from 'react'
import * as React from 'react'
import {
  Container, Grid, TextField, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow,
  Paper, IconButton, Box, Alert, Collapse, Button, CircularProgress
} from '@mui/material'
/* Iconos */
import HomeIcon from '@mui/icons-material/Home'
import CloseIcon from '@mui/icons-material/Close'
import LocationOnIcon from '@mui/icons-material/LocationOn'

import './App.css'
import CasoContext from './store/CasoContext'
/* import { object, string } from 'zod'  */
/* helpers */
import listaFormato from './helpers/lista_formatos'
import calcularDistancia from './helpers/calc_dist_coordenadas'

/* importar json filtrados por locales activos */
import region from './data/regiones.json'
/* import region from './data/regionesFiltradas.json' */
import comunaJson from './data/comunas.json'
/* import comunaJson from './data/comunasFiltradas.json' */
/* import locales from './data/localesqr.json' */
import locales from './data/localesqrFiltrados.json'
import localesAlvi from './data/localesAlvi.json'
import localesM10 from './data/localesM10.json'
import localesS10 from './data/localesS10.json'

import coordenadasAlvi from './data/coordenadas/coordenadasAlviFiltradas.json'
import coordenadasM10 from './data/coordenadas/coordenadasM10Filtradas.json'
import coordenadasS10 from './data/coordenadas/coordenadasS10Filtradas.json'
import coordenadasUnimarc from './data/coordenadas/coordenadasUnimarcFiltradas.json'


interface Region {
  id: number;
  nombre: string;
}
interface Comuna {
  region_id: number,
  id: number;
  nombre: string;
}
interface Row {
  nombre: string;
  direccion: string;
  region_id: number;
  comuna_id: number;
  codigo_local: number;
  formato: string;
}
interface LocalActivo {
  nombre: string;
  direccion: string;
  region_id: number;
  comuna_id: number;
  codigo_local: number;
  formato: string;
}

interface Coordenadas {
  codigo_local: number;
  Latitud: number;
  Longitud: number
}

interface arrayCoordenadas {
  codigo_local: number;
  dist: number;
}

export function Filtro() {
  const context = useContext(CasoContext)
  const navigate = useNavigate()
  let localesActivos: LocalActivo[] = []
  let coordenadas: Coordenadas[] = []


  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [shouldNavigate, setShouldNavigate] = useState(false)
  const selectedFormat = React.useMemo(() => formatUrl(), [])

  switch (selectedFormat?.formato) {
  case 'UNIMARC':
    localesActivos = locales
    coordenadas = coordenadasUnimarc
    break
  case 'SUPER10':
    localesActivos = localesS10
    coordenadas = coordenadasS10
    break
  case 'MAYORISTA10':
    localesActivos = localesM10
    coordenadas = coordenadasM10
    break
  case 'ALVI':
    localesActivos = localesAlvi
    coordenadas = coordenadasAlvi
    break
  default:
    break
  }
  /*   const regiones = region */
  /* const comunas = comunaJson */
  const regiones = filtrarRegionesPorLocalesActivos(region, localesActivos)
  const comunas = filtrarComunasPorLocalesActivos()
  /*   const [regionSelected, setRegionSelected] = useState('') */
  const [filteredComunas, setFilteredComunas] = useState<Comuna[]>(comunas)
  const [selectedComuna, setSelectedComuna] = useState('')
  const [rows, setRows] = useState<Row[]>([])
  const [regionSeleccionada, setRegionSeleccionada] = useState('')

  /* ------------FUNCIONES------------------ */

  /* se determina el formato de la sucursal en base a la url */
  function formatUrl() {
    const segments = window.location.pathname.split('/')
    if (segments.length > 2) {
      const formato = determinarCadena(segments[2])
      return formato
    }
    return null
  }

  function determinarCadena(letraFormato: string) {
    // Encuentra el objeto en gridItems con el mismo valor de letraFormato
    let formato = ''
    switch (letraFormato) {
    case 'u':
      formato = 'UNIMARC'
      break
    case 's':
      formato = 'SUPER10'
      break
    case 'm':
      formato = 'MAYORISTA10'
      break
    case 'a':
      formato = 'ALVI'
      break
    default:
      break
    }
    const gridItem = listaFormato.find(item => item.formato === formato)
    if (gridItem) {
      return gridItem
    } else {
      setShouldNavigate(true)
    }
  }

  function filtrarRegionesPorLocalesActivos(regiones: Region[], localesActivos: LocalActivo[]): Region[] {
    // Se obtienen las region_id presentes en los locales activos
    const regionIdsLocalesActivos = localesActivos.map((local: LocalActivo) => local.region_id)
    // Se filtran las regiones que tienen una region_id presente en los locales activos
    const regionesFiltradas = regiones.filter((region: Region) =>
      regionIdsLocalesActivos.includes(region.id)
    )
    return regionesFiltradas
  }

  function filtrarComunasPorLocalesActivos() {
    // Se obtienen las comuna_id presentes en los locales activos
    const comunaIdsLocalesActivos = localesActivos.map((local: LocalActivo) => local.comuna_id)
    // Se filtran las comunas que tienen una comuna_id presente en los locales activos
    const comunasFiltradas = comunaJson.filter((comuna: Comuna) =>
      comunaIdsLocalesActivos.includes(comuna.id)
    )
    const comunasOrdenadas = comunasFiltradas.sort((a, b) => a.nombre.localeCompare(b.nombre))
    return comunasOrdenadas
  }

  function goToLocal(formato: string, codigo_local: number) {
    let url = ''
    switch (formato) {
    case 'UNIMARC':
      url = (`${'u' + codigo_local}`)
      break
    case 'SUPER10':
      url = (`${'s' + codigo_local}`)
      break
    case 'MAYORISTA10':
      url = (`${'m' + codigo_local}`)
      break
    case 'ALVI':
      url = (`${'a' + codigo_local}`)
      break
    default:
      break
    }
    navigate(`/${url}`)
  }
  /* -------------OBSERVADORES-------------- */
  useEffect(() => {
    if (shouldNavigate) {
      navigate('/')
    }
  }, [shouldNavigate, navigate])

  useEffect(() => {
    if (selectedFormat) {
      // Actualizar el contexto aquí, fuera del ciclo de renderización
      context?.updateField('appFormato', selectedFormat.formato)
      context?.updateField('appColor', selectedFormat.color)
      context?.updateField('appLogo', selectedFormat.imageSrc)
    }
  }, [selectedFormat])

  useEffect(() => {
    // Se verifica si rows es mayor a 4, de ser asi se activa el Alert (true)
    if (Array.isArray(rows) && rows.length > 3) {
      setOpen(true)
    } else {
      setOpen(false)
    }
    if (Array.isArray(rows) && loading) {
      setLoading(false)
    }
  }, [rows])
  /*   useEffect(() => {
    console.log('prueba')
    setRows([]) // se limpia el estado de "rows"
  }, [filteredComunas]) */
  /* Mounted */
  useEffect(() => {
    currentCoordinates()
  }, [])
  /* ----------------- */

  function currentCoordinates() {
    /* Si el navegador permite geolocalización se determina la actual
      y luego se calculan las distancias según sus coordenadas
      con la función helper calcularDistancia()
      a cada uno de los locales del formato actual.
    */
    setRegionSeleccionada('')
    setSelectedComuna('')
    const arrayCoordenadas: arrayCoordenadas[] = []
    setLoading(true)
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        const userLatitude = position.coords.latitude
        const userLongitude = position.coords.longitude

        coordenadas.forEach(element => {
          const distancia = calcularDistancia(userLatitude, userLongitude, element.Latitud, element.Longitud)
          arrayCoordenadas.push({ codigo_local: element.codigo_local, dist: distancia })
        })

        /* se ordenan de menor a mayor distancia */
        arrayCoordenadas.sort((a, b) => a.dist - b.dist)
        //console.log('distancia locales sort',arrayCoordenadas)

        /* los 3 locales más cercanos se asignan al array que es 
          iterado en la tabla de sucursales con la interface necesaria para la tabla */
        let tempRows: Row[] = []
        tempRows = searchLocalById(arrayCoordenadas)

        setRows(tempRows)
      })
    } else {
      console.log('Geolocalización no está disponible en este navegador.')
    }

  }

  function searchLocalById(arrayCoordenadas: Array<{ codigo_local: number, dist: number }>) {
    /* Se buscan los 3 locales más cercanos y en caso de existir (codigo_local) en la 
    lista de localesActivos del formato actual son retornados para su visualización  */
    const tresMasCercanos = arrayCoordenadas.slice(0, 3)
    //console.log('tresMasCercanos',tresMasCercanos)

    const localesCercanos = localesActivos.filter(local => {
      return tresMasCercanos.some(item => item.codigo_local === local.codigo_local)
    })
    return localesCercanos.reverse()
  }

  const handleRegionChange = (event: string) => {
    setRegionSeleccionada(event)
    const regionId = event
    /*     setRegionSelected(regionId) */
    const filtered = comunas.filter((comuna) => comuna.region_id === parseInt(regionId))
    if (filtered.length === 1) {
      setSelectedComuna(filtered[0].id.toString())
      handleComunasChange(filtered[0].id.toString())
    }
    else {
      setSelectedComuna('')
      setRows([])
    }

    setFilteredComunas(filtered)

  }
  const handleComunasChange = (event: string) => {

    const id_comuna = event
    const tempRows: Row[] = []

    localesActivos.forEach((local) => {
      if (local.comuna_id === parseInt(id_comuna)) {
        /* const newRow = createLocalData(local.region_id, local.codigo_local, local.nombre, local.formato, local.direccion); */
        tempRows.push(local)
      }
    })
    setRows(tempRows)
    setSelectedComuna(id_comuna)
  }

  return (
    <>
      <Container maxWidth="sm" className='container'>
        <div className='inside-container'>
          <Grid container spacing={1} >
            <Grid item xs={12}>
              <div style={{ marginTop: '-10px' }}>
                <img
                  style={{
                    width: context?.caso.appFormato === 'ALVI' ? '30%' : ''
                  }}
                  src={context?.caso.appLogo} className="App-logo" alt="logo" />
              </div>
              <span
                style={{
                  marginLeft: '20px',
                  fontWeight: '400',
                  fontSize: '13px',
                }}
              >
                Por favor selecciona una región y una comuna para desplegar los locales disponibles.
              </span>
            </Grid>

            <Grid item xs={12} style={{ marginTop: '0%' }}>
              <TextField
                id="region"
                select
                fullWidth
                margin='none'
                style={{ borderRadius: '30px' }}
                variant="outlined"
                label="Selecciona una región"
                value={regionSeleccionada}
                onChange={(event) => handleRegionChange(event.target.value)}
                SelectProps={{
                  native: true,
                }}
              >
                <option disabled value=""></option>
                {regiones.map((option) => (
                  <option key={option.id} value={option.id} >
                    {option.nombre}
                  </option>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} >

              <TextField
                id="comuna"
                select
                variant="outlined"
                fullWidth
                margin='none'
                label="Seleccione una comuna"
                onChange={(event) => handleComunasChange(event.target.value)}
                value={selectedComuna}
                SelectProps={{
                  native: true,
                }}
              >
                <option disabled value=""></option>
                {filteredComunas.map((comuna) => (
                  <option key={comuna.id} value={comuna.id}>
                    {comuna.nombre}
                  </option>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <Button
                className='location-btn'
                style={{ borderRadius: '5px', backgroundColor: context?.caso.appColor }}
                variant="contained"
                onClick={() => currentCoordinates()}
                endIcon={loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : <LocationOnIcon />}>

                Locales cercanos
              </Button>

            </Grid>


            <Grid item>
              <Box className='alert'>
                <Collapse in={open}>
                  <Alert
                    icon={
                      <section>
                        <span className="scroll-icon">
                          <span className="scroll-icon__dot"></span>
                        </span>
                      </section>}
                    color='info'
                    variant='filled'
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setOpen(false)
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ mb: 0 }}
                  >
                    Desplázate hacia abajo para ver el listado completo de locales disponibles.
                  </Alert>

                </Collapse>

              </Box>

            </Grid>

            {/* tabla filtrada locales */}
            <Grid item xs={12}  >
              <Paper variant="outlined" >
                <TableContainer className='table-scroll' sx={{
                  '&::-webkit-scrollbar-thumb': {
                    background: context?.caso.appColor,
                    borderRadius: '10px',
                  },
                  '&::-webkit-scrollbar': {
                    width: '6px',  // Ajusta el ancho del scrollbar según tus preferencias
                  },
                }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" sx={{ fontSize: '13px', fontWeight: 'bold' }}>
                          Selecciona Sucursal
                        </TableCell>
                      </TableRow>
                      <TableRow>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.length > 0 ? (
                        rows.map((row) => (
                          <TableRow
                            key={row.codigo_local}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                              borderButton: '0px',
                              cursor: 'pointer',
                              '&:hover': { backgroundColor: '#f5f5f5' },
                            }}
                            onClick={() => goToLocal(row.formato, row.codigo_local)}
                          >
                            <TableCell align="center" sx={{ padding: '5px', borderBottom: '1px solid #ccc' }} >
                              {/*                             <Button
                              variant="text"
                              color="error"
                              onClick={() => goToLocal(row.formato, row.codigo_local)}
                              endIcon={<LaunchIcon />}
                              sx={{ fontSize: '14px' }}
                            >
                             
                            </Button> */}
                              <span style={{ fontSize: '14px', color: 'red' }}>
                                {row.nombre}
                              </span>
                              <br />
                              <span style={{ fontSize: '12px', color: '#777' }}>{row.direccion}</span>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell align="center" sx={{ fontStyle: 'italic' }}>
                            
                          </TableCell>

                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>

            <Grid item xs={12}
              className='home-buttom'>
              <IconButton size='large'
                style={{ color: context?.caso.appColor }}
                aria-label="Inicio" onClick={() => navigate('/')}>
                <HomeIcon />
              </IconButton>
            </Grid>
          </Grid>
          {/* 
                          <Button
                  disabled={open}
                  variant="outlined"
                  onClick={() => {
                    setOpen(true)
                  }}
                >
        Re-open
                </Button>
*/}
        </div>

      </Container>

    </>)
}