/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate,useLocation } from 'react-router-dom'
import { useEffect, useContext,useState } from 'react'
import { checkLocalIsActive, findLocal } from './domain/locales'
import { Button, Grid, TextField, FormControlLabel, Checkbox,Container } from '@mui/material'
import './App.css'
import CasoContext from './store/CasoContext'
import { object, string } from 'zod'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft' 
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import useFormatoActual from './helpers/formato_actual'

import logoSuper10 from './logos/Super10_logo.png'
import logoUnimarc from './logos/Unimarc_logo.png'
import logoMayorista10 from './logos/Mayorista10_logo.png'
import logoAlvi from './logos/Alvi_logo.png'

const registerSchema = object({
  nombre: string().min(1, 'debes ingresar nombre'),
  email: string().min(1, 'Email is required').email('Email is invalid'),
  apellidos: string().min(1, 'debes ingresar apellidos'),
  fono: string().min(1, 'debes ingresar telefono'),
  rut: string().min(1, 'debes ingresar tu rut'),
  tyc: string().min(1)
})

export function Datos() {
  const context = useContext(CasoContext)
  const navigate = useNavigate()  
  const location = useLocation()
  const setFormatoActual = useFormatoActual()
  const [politicaUrl, setPoliticaUrl] = useState('')

  /* cambio de politicas de seguridad según el contexto (formato) actual */
  useEffect(() => {
    switch (context?.caso.appFormato) {
    case 'ALVI':
      setPoliticaUrl('https://assets.ctfassets.net/un6yvtd6uq5z/2rQ0QeysHYFsKcf46LIs4T/f75173988414b6ebe0eb52f80e097c9a/Poli_tica_de_Privacidad_SAC_ALVI_V.25.04.2023_nuevo_GG.pdf')
      break
    case 'UNIMARC':
      setPoliticaUrl('https://assets.ctfassets.net/un6yvtd6uq5z/4U3MIh0FZx7YXOk1sIXZME/21e6f2a2d40ca9d3f6764e2fc69c4d13/Pol__tica_de_Privacidad_SAC_UNIMARC_V.25.04.2023.pdf')
      break
    case 'SUPER10':
      setPoliticaUrl('https://assets.ctfassets.net/un6yvtd6uq5z/3ZmpLw54ZI9G3ptnMQqkF7/095187d6dd6f05807fa8b9e7ef0c4a4d/Poli__tica_de_Privacidad_SAC_S10_V.25.04.2023.pdf')
      break
    case 'MAYORISTA10':
      setPoliticaUrl('https://assets.ctfassets.net/un6yvtd6uq5z/6PGQsajlGKMhlXnuHjIWiL/5badea3f2237ab52cecc3b7e2bb071b9/Politica_de_Privacidad_SAC_M10_V.25.04.2023.pdf')
      break
    default:
      break
    }
  })

  useEffect(() => {
    
    const prefix = location.pathname.split('/').join('')

    if (context?.caso.appFormato === '') {
      if (prefix.includes('u')) {
        setFormatoActual('UNIMARC', '#DE1721', logoUnimarc)
      } else if (prefix.includes('s')) {
        setFormatoActual('SUPER10', '#F6631C', logoSuper10)
      } else if (prefix.includes('a')) {
        setFormatoActual('ALVI', '#003C8A', logoAlvi)
      } else if (prefix.includes('m')) {
        setFormatoActual('MAYORISTA10', '#E25F22', logoMayorista10)
      }
    }

    if (checkLocalIsActive(location.pathname.split('/').join(''))) {
      if (location.pathname && context) {
        context.updateField('local',location.pathname)
        const local=findLocal(location.pathname.split('/').join(''))
        if (local) {
          context.updateField('localNom', local?.name)
          context.updateField('localNum', ''+local?.number)
        }
      }
    } else {
      /* console.log(location.pathname) */
      navigate(`/${'search'+location.pathname}`)
    } 
    
  }, [location.pathname, navigate])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validaCaso = (caso:any) => {
    const resul = registerSchema.safeParse(caso)
    return resul.success
  }

  function navigateFormato(){
    const segments = window.location.pathname.split('/')
    if (segments.length > 1) {
      const formato = segments[1].charAt(0).toLowerCase()
      
      if(formato){
        navigate(`/${'search/'+formato}`)
      } else {
        navigate('/') 
      }
       
    }
    return null
  }

  return (
    <>
      <Container maxWidth="sm" className='container'>
        <div className='inside-container'>
          <Grid container  spacing={2}>
            <Grid item xs={12}>
              <div>
                <img 
                  style={{
                    width: context?.caso.appFormato === 'ALVI' ? '30%': ''
                  }}
                  src={context?.caso.appLogo} className="App-logo" alt="logo" />
              </div>
              <p>
                <span style={{color:'#777', fontWeight: '600', fontSize:'20px'}}>Local {context?.caso.localNom} ({context?.caso.localNum})</span>
                <br/>Hola! Para poder contactarnos contigo, favor ingresa tus datos y revisa términos y condiciones.</p>
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="given-name"
                name="nombre"
                required
                fullWidth
                size="small"
                className='inpus'
                id="nombre"
                label="Nombre"
                sx={{ bgcolor: '#f5f5f5' }}
                onChange={context?.handleInput}
                value={context?.caso.nombre}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                size="small"
                id="apellidos"
                label="Apellidos"
                name="apellidos"
                sx={{ bgcolor: '#f5f5f5' }}
                onChange={context?.handleInput}
                value={context?.caso.apellidos}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                size="small"
                id="rut"
                label="RUT / RUN"
                name="rut"
                sx={{ bgcolor: '#f5f5f5' }}
                onChange={context?.handleInput}
                value={context?.caso.rut}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                size="small"
                id="fono"
                label="Teléfono"
                name="fono"
                sx={{ bgcolor: '#f5f5f5' }}
                onChange={context?.handleInput}
                value={context?.caso.fono}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                size="small"
                id="email"
                label="Correo Electrónico"
                name="email"
                sx={{ bgcolor: '#f5f5f5' }}
                onChange={context?.handleInput}
                value={context?.caso.email}
              />
            </Grid>
            <Grid item xs={12} sx={{mt: 2}}>
              {/*               <a 
                style={{textDecoration:'underline', color:'blue', fontSize:'0.9rem', cursor:'pointer'}}  
                href="https://locales.unimarc.cl/wp-content/uploads/2022/08/politica_privacidad_sac_v2.pdf"
                target="_blank" rel="noreferrer"
              >ver términos y condiciones</a><br/> */}
              <a 
                style={{textDecoration:'underline', color:'blue', fontSize:'0.9rem', cursor:'pointer'}}  
                href={politicaUrl}
                target="_blank" rel="noreferrer"
              >Ver Política de Privacidad de la plataforma de Servicio al Cliente</a>
              <br/><br />
              <FormControlLabel style={{marginTop: '-10px'}}
                control={<Checkbox value="AceptaTyC" color="primary" name="tyc" id="tyc" onChange={context?.handleInput}/>}
                label="Acepto la Política de Privacidad de la plataforma de Servicio al Cliente."
                className='fcontrol'
              />
            </Grid>
            <Grid item xs={12} container style={{marginTop: '0px', marginBottom: '10px'}}>
              <Grid item xs={6}>
                <Button  
                  style={{borderRadius: '10px',color: context?.caso.appColor}} 
                  variant="text" size='large' 
                  onClick={() =>  navigateFormato()} 
                  startIcon={<KeyboardArrowLeftIcon />}>volver</Button>
              </Grid>
              <Grid item xs={5}>
                <Button style={{borderRadius: '10px'}} className='buttom-continue'  variant="contained" color="success" disabled={!validaCaso(context?.caso)} onClick={() => navigate('/dat')}
                  endIcon={<KeyboardArrowRightIcon />}>
                Continuar </Button>
              </Grid>

            </Grid>
          </Grid>

        </div>
      </Container>

    </> )
}