import axios from 'axios'

export type SaveCasoResult = {
  id_ticket: string,
  result: boolean
}
/* eslint-disable  @typescript-eslint/no-explicit-any */
export function saveCaso(caso:any):Promise<SaveCasoResult> {
  const data = new URLSearchParams(caso).toString()
  return new Promise((resolve, reject) => {
    const options = {
      method: 'POST',
      crossDomain: true,
      data,
      url: process.env.REACT_APP_CASO_URL
    }
    axios(options).then((response) => {
      
      const result:SaveCasoResult = {
        id_ticket: ''+response.data.id_ticket,
        result: true
      }
      resolve(result)
    }).catch((error) => {
      const result:SaveCasoResult = {
        id_ticket: '0',
        result: false
      }

      reject(result)
    }
    )
  })
}