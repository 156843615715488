import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import './App.css'
import CasoContext from './store/CasoContext'
import Box from '@mui/material/Box'
import {Grid, Container, Button} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
export function Send() {
  const navigate = useNavigate() 
  const context = useContext(CasoContext)

  function finalizar(){
    let formato = ''
    switch (context?.caso.appFormato) {
    case 'ALVI':
      formato = 'a'
      break  
    case 'S10':
      formato = 's'
      break 
    case 'M10':
      formato = 'm'
      break 
    case 'UNIMARC':
      formato = 'u'
      break 
    default:
      break
    }
    navigate(`${'/'+formato}`)
  }

  return (
    <>
      {context!==null && 
        <Container maxWidth="sm" className='container sec3'>
          <Box component="form" noValidate  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '2%' }}>
            <Grid container  spacing={3}>
              <Grid item xs={12}>
                <div style={{marginTop:'10px'}}>
                  <img 
                    style={{
                      width: context?.caso.appFormato === 'ALVI' ? '30%': ''
                    }}
                    src={context?.caso.appLogo} className="App-logo" alt="logo" />
                </div>
              </Grid>
              <Grid item xs={12}>
                <span style={{color:'#777', fontWeight: '600', fontSize:'1.2rem'}}>Local {context?.caso.localNom} ({context?.caso.localNum})</span>
              </Grid>
              <Grid item xs={12} style={{padding: '5%'}}>
                <p>Su caso de <b>{context?.caso.tipo_caso}</b> ha sido ingresado(a) 
                    bajo el número de <b style={{fontSize:'1.2rem'}}>Ticket N° {context?.caso.id_ticket}</b>.</p>
                <br/>
                <p>Le llegará un correo electrónico con el detalle de su caso y el número de ticket asociado.</p>

                <p>Ante consulta y/o seguimiento nos puede contactar al <strong>600 600 0025</strong></p>
              </Grid>
              <Grid item xs={12}>
                <Button style={{borderRadius: '10px', color: context?.caso.appColor}} 
                  
                  variant="text"  
                  onClick={() => finalizar()}
                  endIcon={<CheckCircleIcon />}>
                  finalizar</Button>
                
              </Grid>
            </Grid>
          </Box>
        </Container>
      }

    </>
  )
}