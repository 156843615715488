import logoUnimarc from '../logos/Unimarc_logo.png'
import logoSuper10 from '../logos/Super10_logo.png'
import logoMayorista10 from '../logos/Mayorista10_logo.png'
import logoAlvi from '../logos/Alvi_logo.png'


const gridItems = [
  { formato: 'UNIMARC', imageSrc: logoUnimarc , color: '#DE1721'},
  { formato: 'SUPER10', imageSrc: logoSuper10 , color: '#F6631C'}, 
  { formato: 'MAYORISTA10', imageSrc: logoMayorista10 , color: '#E25F22' },
  { formato: 'ALVI', imageSrc: logoAlvi , color: '#003C8A'},
]

export default gridItems