import { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Box, Button, Grid, Container } from '@mui/material'
import './App.css'
import CasoContext from './store/CasoContext'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
export function Tipo() {

  const context = useContext(CasoContext)

  useEffect(() => {
    // console.log('Tipo:',context?.caso)
  }, [])

  return (
    <>
      {context!==null && 
        <Container maxWidth="sm" className='container sec1'>

          <Box component="form" noValidate  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '2%' }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <img 
                  style={{
                    width: context?.caso.appFormato === 'ALVI' ? '30%': ''
                  }}
                  src={context?.caso.appLogo} className="App-logo" alt="logo" />
              </Grid>
              <Grid item xs={12}>
                <span style={{color:'#777', fontWeight: '600', fontSize:'1.2rem'}}>Local {context?.caso.localNom} ({context?.caso.localNum})</span>
                <br/>Escoge la opción que se acomode a tu caso
              </Grid>
              <Grid item xs={12}>
                <Link to='/cla' onClick={()=>context.updateField('tipo_caso','Reclamo')} style={{textDecoration: 'none'}}>
                  <Button variant="contained" className='btn-ini' style={{backgroundColor: context?.caso.appColor, borderRadius: '15px'}}>Reclamo</Button>
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Link to="/cla" onClick={()=>context.updateField('tipo_caso','Consulta')} style={{textDecoration: 'none'}}>
                  <Button variant="contained" className='btn-ini' style={{backgroundColor: context?.caso.appColor, borderRadius: '15px'}}>Consulta</Button>
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Link to="/det" onClick={()=>context.updateField('tipo_caso','Producto no Encontrado')} style={{textDecoration: 'none'}}>
                  <Button variant="contained" className='btn-ini' style={{backgroundColor: context?.caso.appColor, borderRadius: '15px'}}>Producto no Encontrado</Button>
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Link to="/det" onClick={()=>context.updateField('tipo_caso','Felicitaciones')} style={{textDecoration: 'none'}}>
                  <Button variant="contained" className='btn-ini' style={{backgroundColor: context?.caso.appColor, borderRadius: '15px'}}>Felicitaciones</Button>
                </Link>
              </Grid>


              <Grid item xs={12}>
                <Link to={context?.caso.local} className='btn-ini' >
                  <Button style={{borderRadius: '10px', color: context?.caso.appColor}} variant="text" size='large'  startIcon={<KeyboardArrowLeftIcon />}>Volver</Button>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Container>
      }
    </>
  )
}