import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import {  useContext } from 'react'
import { Tipo } from './sec_tipo'
import { Datos } from './sec_datos'
import { Detalle } from './sec_detalle'
import { Locales } from './sec_local'
import { Clase } from './sec_clase'
import { Send } from './sec_send'
import { Filtro } from './sec_filtro'
import { Home } from './sec_home'
import CasoContext from './store/CasoContext'
import './App.css'

function App() {
  const context = useContext(CasoContext)
  return (
    <div className="App" style={{backgroundColor: context?.caso.appColor}}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />

          
          <Route path="/search/*" element={<Filtro />} />
          <Route path="/det" element={<Detalle />} />
          <Route path="/dat" element={<Tipo />} />
          <Route path="/cla" element={<Clase />} />
          <Route path="/loc" element={<Locales />} />
          <Route path="/send" element={<Send />} />


          <Route path="/*" element={<Datos />} />
        </Routes>
      </Router>
      {/* Comentario  */}
    </div>
  )
}

export default App
