import React from 'react'
import ReactDOM from 'react-dom/client'
/* import './fonts/AvenirLTStd-Medium.ttf'
import './fonts/AvenirLTStd-Black.ttf'
import './fonts/AvenirLTStd-Light.ttf' */
import './index.css'
import ContextState from './store/ContextState'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <ContextState />
  </React.StrictMode>
)

