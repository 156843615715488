import { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Box, Button, Grid, Container } from '@mui/material'
import './App.css'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import CasoContext from './store/CasoContext'

export function Clase() {

  const context = useContext(CasoContext)

  useEffect(() => {
    // console.log('Clase:',context?.caso)
  })

  return (
    <>
      {context!==null && 
        <Container maxWidth="sm" className='container sec2'>

          <Box component="form" noValidate  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '2%' }}>
            <Grid container  spacing={2}>
              <Grid item xs={12}>
                <img 
                  style={{
                    width: context?.caso.appFormato === 'ALVI' ? '30%': ''
                  }}
                  src={context?.caso.appLogo} className="App-logo" alt="logo" />
              </Grid>
              <Grid item xs={12}>
                <span style={{color:'#777', fontWeight: '600', fontSize:'1.2rem'}}>
                  Local {context.caso.localNom} ({context.caso.localNum})</span>
                <br/>Seleccione una opción para iniciar su caso de {context.caso.tipo_caso}
              </Grid>


              { context.caso.tipo_caso === 'Reclamo' &&
              <>
                <Grid item xs={12}>
                  <Link to='/det' onClick={()=>context.updateField('clase','Calidad')} style={{textDecoration: 'none'}}>
                    <Button variant="contained" className='btn-ini' style={{backgroundColor: context?.caso.appColor, borderRadius: '15px'}}>Calidad</Button>
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <Link to='/det' onClick={()=>context.updateField('clase','Stock')} style={{textDecoration: 'none'}}>
                    <Button variant="contained" className='btn-ini' style={{backgroundColor: context?.caso.appColor, borderRadius: '15px'}}>Stock</Button>
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <Link to='/det' onClick={()=>context.updateField('clase','Precio')} style={{textDecoration: 'none'}}>
                    <Button variant="contained" className='btn-ini' style={{backgroundColor: context?.caso.appColor, borderRadius: '15px'}}>Precio</Button>
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <Link to='/det' onClick={()=>context.updateField('clase','Promociones')} style={{textDecoration: 'none'}}>
                    <Button variant="contained" className='btn-ini' style={{backgroundColor: context?.caso.appColor, borderRadius: '15px'}}>Promociones</Button>
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <Link to='/det' onClick={()=>context.updateField('clase','Mala Atencion')} style={{textDecoration: 'none'}}>
                    <Button variant="contained" className='btn-ini' style={{backgroundColor: context?.caso.appColor, borderRadius: '15px'}}>Mala Atención</Button>
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <Link to='/det' onClick={()=>context.updateField('clase','Otros')} style={{textDecoration: 'none'}}>
                    <Button variant="contained" className='btn-ini' style={{backgroundColor: context?.caso.appColor, borderRadius: '15px'}}>Otros</Button>
                  </Link>
                </Grid>

              </>
              }

              { context.caso.tipo_caso === 'Consulta' &&
              <>
                <Grid item xs={12}>
                  <Link to='/det'  style={{textDecoration: 'none'}} onClick={()=>{context.updateField('clase','Club Ahorro')}}>
                    <Button variant="contained" className='btn-ini' style={{backgroundColor: context?.caso.appColor, borderRadius: '15px'}}>Club Ahorro</Button>
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <Link to='/det'  style={{textDecoration: 'none'}} onClick={()=>{context.updateField('clase','Stock')}}>
                    <Button variant="contained" className='btn-ini' style={{backgroundColor: context?.caso.appColor, borderRadius: '15px'}}>Stock</Button>
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <Link to='/det'  style={{textDecoration: 'none'}} onClick={()=>{context.updateField('clase','Promociones')}}>
                    <Button variant="contained" className='btn-ini' style={{backgroundColor: context?.caso.appColor, borderRadius: '15px'}}>Promociones</Button>
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <Link to='/det'  style={{textDecoration: 'none'}} onClick={()=>{context.updateField('clase','Otros')}}>
                    <Button variant="contained" className='btn-ini' style={{backgroundColor: context?.caso.appColor, borderRadius: '15px'}}>Otros</Button>
                  </Link>
                </Grid>
              </>
              }

              <Grid item xs={12} style={{marginBottom: '10%'}}>
                <Link to='/dat' className='btn-ini' >
                  <Button style={{borderRadius: '10px',color: context?.caso.appColor}} variant="text" size='large' color="warning"  startIcon={<KeyboardArrowLeftIcon />}>Volver</Button>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Container>
      }
    </>
  )
}