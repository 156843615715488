/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import logo from './logos/Unimarc_logo.png'
import { Box, Button, Grid } from '@mui/material'
import { Link } from 'react-router-dom'
import './App.css'
import CasoContext from './store/CasoContext'

import SearchIcon from '@mui/icons-material/Search'

export function Locales() {

  const { local } = useParams()
  const context = useContext(CasoContext)

  useEffect(()=>{
    if (local && context)
      context.updateField('local',local)
  },[local])

  return (
    <>
      {context!==null && 
        <div className="container sec1">
          <div>
            <img src={logo} className="App-logo" alt="logo" />
          </div>
          <div style={{padding: '40px'}}>
            <p>Favor vuelva a escanear su <strong>Codigo QR</strong> en sala o escriba en el navegador de su celular el link que se encuentra debajo del código QR.</p>
          </div>


          {/* botón a filtro */}
          <div>
            <p style={{paddingLeft: '40px',paddingRight: '40px'}}>
              Si desea buscar la tienda de forma manual presiona a continuación.
            </p>
            <Box component="div"  sx={{ mt: 2, ml: 3, mr: 3 }}>
              <Grid container  spacing={3}>
                <Grid item xs={12}>
                  <Link to="/fil" style={{textDecoration: 'none'}}>
                    <Button variant="contained" className='btn-ini' color="error" 
                      startIcon={<SearchIcon />}>
                      Buscador manual
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      }
    </>
  )
}