import React, { useReducer } from 'react'
import CasoContext from './CasoContext'
import App from '../App'

const initialState = {
  local: '',
  tipo_caso:'',
  nombre: '',
  apellidos: '',
  rut: '',
  fono: '',
  email: '',
  tyc:'',
  initial:false,
  appColor: 'DE1721',
  appFormato: '',
  appLogo: ''
}
/* eslint-disable  @typescript-eslint/no-explicit-any */
const ReclamoReducer = (state:any = initialState, action:any) => {
  const newState = {
    ...state,
    ...action,
    initial:false,
  }
  if ('reset' in action)  {
    return {
      local:state.local,
      localNom:state.localNom,
      localNum:state.localNum,
      tipo_caso:'',
      nombre: state.nombre,
      apellidos: state.apellidos,
      rut: state.rut,
      fono: state.fono,
      email: state.email,
      initial: false,
      tyc: state.tyc,
      appColor: state.appColor,
      appFormato: state.appFormato,
      appLogo: state.appLogo
    }
  }
  if (state.initial===true) {
    const localStore = window.localStorage.getItem('reclamo')
    if (localStore) {
      const localState = JSON.parse(localStore)
      if (localState?.initial===false) {
        return {
          tipo_caso:'',
          nombre: localState.nombre,
          apellidos: localState.apellidos,
          rut: localState.rut,
          fono: localState.fono,
          email: localState.email,
          initial: false,
          tyc:'',
          appColor: '',
          appFormato: 'UNIMARC',
          appLogo: ''
        }
      }
    }
  } 
  window.localStorage.setItem('reclamo',JSON.stringify(newState))
  return newState
}

const ContextState = () => {
  const [stateReclamoReducer, dispatchReclamoReducer] = useReducer(
    ReclamoReducer,
    initialState
  )

  
  const handleInput = (evt : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    switch (evt.target.type) {
    case 'checkbox':
      const chbox = evt.target as HTMLInputElement
      updateField(chbox.name,chbox.checked?chbox.value:'')
      break
    default:
      const name = evt.target.name
      const newValue = evt.target.value
      updateField(name,newValue)
    }
  }

  const updateField = (name:string, newValue:string) => {
    dispatchReclamoReducer({ [name]: newValue})
  }

  return (
    <CasoContext.Provider
      value={{
        caso: stateReclamoReducer,
        handleInput,
        updateField
      }}
    >
      <App />
    </CasoContext.Provider>
  )
}

export default ContextState